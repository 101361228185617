import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import {TsAdminContext} from "../../context/ts-admin";
import {Button, Form, Spinner, Table} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {getListEmp} from "../../api-service/employee";
import * as JSZip from "jszip"
import {saveAs} from 'file-saver';
import QRCode, {QRCodeCanvas, QRCodeSVG} from 'qrcode.react';
import * as _ from 'lodash';

interface ItemEmployee {
    isSelected: boolean,
    empCode: string,
    empId: string,
    isFilter: boolean,
    empName: string,
    cardIdm: []
}

function sortAscending(ascending) {
    return function (a, b) {
        if (a.empCode === b.empCode) {
            return 0;
        }
        if (a.empCode === null) {
            return 1;
        }
        if (b.empCode === null) {
            return -1;
        }
        if (ascending) {
            return a.empCode < b.empCode ? -1 : 1;
        }
        return a.empCode < b.empCode ? 1 : -1;
    };
}


export function QrCodeReader() {
    const [orgInfo] = useContext(TsAdminContext);
    const [showModal, setShowModal] = useState(false);
    const [disableExport, setDisableExport] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingPage, setLoadingPage] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(false);
    const [sortType, setSortType] = useState('desc');
    const [records, setRecords] = useState<ItemEmployee[]>([]);
    const [totalRecordsShow, setTotalRecordsShow] = useState<number>(0);
    const [error, setError] = useState("");
    const [isCheckAll, setIsCheckAll] = useState(false);
    const onCheckAll = async () => {
        let tmpRecords = _.cloneDeep(records);
        if (isCheckAll) {
            tmpRecords.map(e => {
                if (e.isFilter) {
                    e.isSelected = false
                    return e;
                }
            });
            setIsCheckAll(false);
        } else {
            tmpRecords.map(e => {
                e.isSelected = true
                return e;
            });
            setIsCheckAll(true);
        }
        setRecords(tmpRecords);
    }
    const [formFilter, setFormFilter] = useState({
        empCode: "",
        empName: "",
    });
    const resetModal = () => {
        setError("");
        setLoading(false);
        setLoadingPage(false);
        setIsCheckAll(false);
        setLoadingFilter(false);
        setDisableExport(true)
        setSortType('desc')
    }
    useEffect(() => {
        if (showModal) {
            setFormFilter({
                empCode: "",
                empName: "",
            });
            resetModal();
            const getListEmployee = async () => {
                setLoadingPage(true);
                await getListEmp({
                    targetOrg: `${localStorage.getItem('target-org') || orgInfo?.currentOrg?.OrgId}`,
                }).then((res: any) => {
                    if (res && res.users.length > 0) {
                        console.log(res.users, "res.users")
                        const tmpRecords: ItemEmployee[] = res.users.sort(sortAscending(false));
                        console.log(22, tmpRecords)
                        tmpRecords.map(e => {
                            e.isSelected = false
                            e.isFilter = true
                            return e;
                        })
                        console.log("tmpRecords", tmpRecords)
                        setRecords(tmpRecords);
                    }

                    setLoadingPage(false);
                }).catch((e) => {
                    setLoadingPage(false);
                    setRecords([]);
                    setError(e.message.error)
                });
            }
            getListEmployee();
        } else {
            setRecords([])
        }

    }, [showModal])
    const columns = [
        {
            dataField: 'isSelected',
            text: '',
        },
        {
            dataField: 'empCode',
            text: '社員コード',
        }, {
            dataField: 'empName',
            text: '社員名',
        }
    ];


    const handleCloseModal = () => {
        setShowModal(false);
    }

    useEffect(() => {
        let numberRecordShow;

        numberRecordShow = records.filter(e => e.isFilter).length
        setTotalRecordsShow(numberRecordShow)
        setDisableExport(records.filter(e => e.isSelected && e.isFilter).length == 0);
    }, [records])

    const generateQrCode = async () => {
        setLoading(true);
        try {
            const zip = new JSZip();
            const qrImage: any = await new Promise((resolve) => {
                const canvas: any = document.getElementsByTagName('canvas');
                console.log("canvas");
                let base64Canvas: any = []
                for (let i = 0; i < canvas.length; i++) {
                    const empId = canvas[i].accessKey
                    if (records.find(e => e.isSelected && e.empId == empId)) {
                        base64Canvas.push({
                            name: canvas[i].getAttribute('itemid'),
                            content: canvas[i].toDataURL("image/png").split(';base64,')[1]
                        });
                    }
                }

                resolve(base64Canvas);
            });
            console.log(qrImage);
            if (qrImage.length > 0) {
                for (let i = 0; i < qrImage.length; i++) {
                    zip.file(qrImage[i].name + '.png', qrImage[i].content, {base64: true});
                }

                zip.generateAsync({type: "blob"})
                    .then(function (content) {
                        saveAs(content, "Teamspirit_qrcode" + '.zip');
                    });
            }

        } catch (error) {
            console.error('Error exporting QR code to ZIP:', error);
        } finally {
            setLoading(false);

        }

    }

    const onSelect = (event, item) => {
        let tmpRecords = _.cloneDeep(records);
        tmpRecords.map(e => {
            if (e.empId === item.empId) {
                e.isSelected = !e.isSelected
            }
            return e;
        });
        setDisableExport(tmpRecords.filter(e => e.isSelected).length == 0);
        console.log("tmpRecords", tmpRecords)
        setRecords(tmpRecords);
    }

    const doFilter = () => {
        const tmpFilter = {
            empCode: formFilter.empCode.trim(),
            empName: formFilter.empName.trim(),
        }
        console.log(tmpFilter);
        setLoadingFilter(true);
        setTimeout(() => {
            let tmpRecords = _.cloneDeep(records);
            tmpRecords.map((e: ItemEmployee) => {
                let isFilterName = false;
                let isFilterCode;
                if (tmpFilter.empCode != '') {
                    isFilterCode = e.empCode != null ? e.empCode.indexOf(tmpFilter?.empCode) > -1 : false
                } else {
                    isFilterCode = true
                }
                if (e.empName) {
                    isFilterName = e.empName.indexOf(tmpFilter?.empName) > -1
                }
                e.isFilter = isFilterName && isFilterCode
                e.isSelected = false
                return e;
            });
            setIsCheckAll(false);
            setLoadingFilter(false);
            setRecords(tmpRecords);
        }, 500)

    }

    const resetFilter = () => {
        setFormFilter({
            empCode: "",
            empName: ""
        })
        let tmpRecords = _.cloneDeep(records);
        tmpRecords.map(e => {
            e.isFilter = true
            e.isSelected = false
            return e;
        });
        setIsCheckAll(false);
        setRecords(tmpRecords);
    }

    const sortRecord = (index) => {
        if (index != 1) {
            return;
        }
        const newSortType = sortType === 'desc' ? 'asc' : 'desc';
        setSortType(newSortType);
        console.log("onSort", newSortType)
        let tmpRecords = _.cloneDeep(records);
        if (newSortType == 'desc') {
            tmpRecords.sort(sortAscending(false));
        } else {
            tmpRecords.sort(sortAscending(true));
        }
        setRecords(tmpRecords);
    }

    const genFileName = (item, cardId) => {
        let fileName = `${item.empCode ? item.empCode + '_' + item.empName : item.empName}`;
        fileName = fileName + "_" + cardId

        return fileName;
    }

    return (
        <Fragment>
            <Modal size='lg' className="pt-5 modal-gen-qrcode" backdrop='static' show={showModal}
                   onHide={handleCloseModal}>

                <Modal.Header closeButton>
                    QRコードを発行
                </Modal.Header>
                <Modal.Body>
                    {
                        error && <p className="error">{error}</p>
                    }

                    <p className="p-desc">QRコードを発行する社員にチェックを入れてください。</p>
                    <p className="p-desc">※全員分のQRコードを発行する場合は、一番上のチェックボックスを選択すると、全員にチェックが入ります。</p>
                    <h4 className="fw-bold filter-title mt-3 mb-2">絞り込み検索</h4>

                    <div className="form-filter d-flex">
                        <div className="d-flex align-items-center">
                            <label
                                className="fs-13 me-3"
                                htmlFor={`reservation-notice-title`}>社員コード</label>

                            <Form.Control className="w-60 h-auto" size="sm"
                                          value={formFilter.empCode}
                                          onChange={(e) => {
                                              setFormFilter({
                                                  ...formFilter,
                                                  empCode: e.target.value
                                              })
                                          }}
                                          type={'text'}
                            ></Form.Control>

                        </div>
                        <div className="d-flex align-items-center">
                            <label
                                className="fs-13 me-3"
                                htmlFor={`reservation-notice-title`}>社員名</label>

                            <Form.Control className="w-60 h-auto" size="sm"
                                          value={formFilter.empName}
                                          onChange={(e) => {
                                              setFormFilter({
                                                  ...formFilter,
                                                  empName: e.target.value
                                              })
                                          }}
                                          type={'text'}
                            ></Form.Control>

                        </div>

                        <button type={'button'} onClick={doFilter}
                                disabled={(loadingFilter)}
                                className={`btn btn-screen-password ${(loadingFilter) ? 'btn-disabled not-allowed' : ''}`}
                        >  {
                            loadingFilter && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }検索
                        </button>

                        <a onClick={resetFilter}
                           className={'btn-reset-filter mx-3 d-flex align-items-center text-decoration-none'}>絞り込み条件クリア
                        </a>
                    </div>
                    <div className={
                        `content-emp w-100 text-center mt-3 ${loadingPage ? 'd-flex align-items-center m-auto' : ''}`
                    }>
                        {
                            loadingPage ? <h6 className="text-center fw-bold w-100">検索中・・・・</h6> :
                                <div className="w-100">

                                    <div className='table-employee flex'>
                                        <Table className="table-employee-content mb-0">
                                            <thead>
                                            <tr>
                                                {
                                                    columns.map((e, index) => {
                                                        if (index == 0) {
                                                            return (
                                                                <th key={index} style={{width: "50px"}}>
                                                                    <Form.Check className="mb-0 float-start">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            value={1}
                                                                            id={`form-check-all`}
                                                                            checked={isCheckAll}
                                                                            onChange={() => onCheckAll()}
                                                                        />

                                                                    </Form.Check>
                                                                </th>)
                                                        } else {
                                                            return (
                                                                <th key={index}>
                                                                    <div className="d-flex cursor-pointer"
                                                                         onClick={() => sortRecord(index)}>
                                                                        {e.text}
                                                                        {
                                                                            index == 1 && <div
                                                                                className={`${sortType == 'desc' ? 'btn-sort-desc' : 'btn-sort-asc'}`}>
                                                                                <svg
                                                                                    width="12" height="12"
                                                                                    viewBox="0 0 12 12"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fillRule="evenodd"
                                                                                          clipRule="evenodd"
                                                                                          d="M2.21537 7.15392C2.03075 7.33854 2.03075 7.59238 2.21537 7.777L5.6769 11.1693C5.86152 11.3539 6.13844 11.3539 6.32306 11.1693L9.80767 7.777C9.99229 7.59238 9.99229 7.33854 9.80767 7.15392L9.16152 6.53084C8.9769 6.34623 8.69998 6.34623 8.51537 6.53084L7.43075 7.59238C7.24614 7.777 6.92306 7.66161 6.92306 7.38469V1.15392C6.92306 0.923152 6.71537 0.692383 6.46152 0.692383H5.53844C5.2846 0.692383 5.0769 0.946229 5.0769 1.15392V7.38469C5.0769 7.66161 4.75383 7.777 4.56921 7.59238L3.4846 6.53084C3.29998 6.34623 3.02306 6.34623 2.83844 6.53084L2.21537 7.15392Z"
                                                                                          fill="#747474"/>
                                                                                </svg>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </th>
                                                            )
                                                        }

                                                    })
                                                }
                                                <th className="d-none"></th>
                                            </tr>
                                            </thead>

                                            <tbody
                                                className={`${totalRecordsShow > 0 ? '' : 'd-flex align-items-center m-auto'}`}>
                                            {totalRecordsShow > 0 ?
                                                <>
                                                    {
                                                        records.map((item, key) => {
                                                            return (
                                                                <tr key={key} className={item.isFilter ? '' : 'd-none'}>
                                                                    <td style={{width: "50px"}}
                                                                        className={key == records.length - 1 ? 'border-0' : ''}>
                                                                        <Form.Check className="mb-0 float-start">
                                                                            <Form.Check.Input
                                                                                id={item.empId}
                                                                                type="checkbox"
                                                                                value={item.empId}
                                                                                checked={item.isSelected}
                                                                                onChange={(event) => onSelect(event, item)}
                                                                            />

                                                                        </Form.Check>

                                                                    </td>

                                                                    <td className={key == records.length - 1 ? 'border-0' : ''}>{item?.empCode}</td>
                                                                    <td className={key == records.length - 1 ? 'border-0' : ''}>{item?.empName}</td>

                                                                    <td className="d-none">
                                                                        {
                                                                            item.cardIdm.length > 0 &&
                                                                            item.cardIdm.map((cardId, iCard) => {
                                                                                return (<QRCodeCanvas
                                                                                    key={iCard}
                                                                                    bgColor={'#fff'}
                                                                                    size={152}
                                                                                    marginSize={13}
                                                                                    accessKey={item.empId}
                                                                                    itemID={genFileName(item, cardId)}
                                                                                    value={`cid=${cardId}`}
                                                                                />)
                                                                            })
                                                                        }
                                                                    </td>

                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </>

                                                :
                                                <h6 className="text-center fw-bold w-100 m-auto">データがありません。</h6>
                                            }
                                            </tbody>
                                        </Table>

                                    </div>


                                </div>

                        }
                    </div>


                </Modal.Body>
                <Modal.Footer className="modal-footer">
                    <Button variant="light" size="sm" className="modal-btn modal-cancel-btn"
                            onClick={handleCloseModal}>
                        キャンセル
                    </Button>
                    <Button onClick={generateQrCode} size="sm"
                            disabled={loading || disableExport}
                            className={`border text-white px-4 ${(loading) ? 'btn-disabled not-allowed' : 'btn-save'}`}>
                        {
                            loading && <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        }
                        保存場所を選択して発行
                    </Button>

                </Modal.Footer>

            </Modal>

            {
                orgInfo &&
                <Fragment>
                    <div key="inline-radio" className="w-50">
                        <p className="mb-0 mt-3 fs-13">社員のQRコードを発行します</p>
                        <p className="mb-0 fs-13 mt-3">
                            <button type={'button'} onClick={() => setShowModal(true)}
                                    className={'btn btn-screen-password'}>QRコードを発行する社員を選択
                            </button>
                        </p>
                    </div>


                </Fragment>
            }


        </Fragment>
    )

}