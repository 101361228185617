import {apiGet, apiPost} from "./api";

export const getAttendanceLog = async (data) => {
    return await apiGet('stamp-log', data);
}

export const getStampDateLog = async (data) => {
    return await apiGet('stamp-date-log', data);
}
export const getStampDateLogHistory = async (data) => {
    return await apiGet('stamp-date-log-history', data);
}

export const sendAttendanceLog = async (data) => {
    return await apiPost('stamp-date-log', data);
}
