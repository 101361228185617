import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import React, {Fragment, useCallback, useContext, useEffect, useState} from "react";
import {IconArrow} from "../../parts/icon";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {format, formatISO, subDays} from 'date-fns'
import "react-datetime/css/react-datetime.css";
import 'react-loading-skeleton/dist/skeleton.css'
import leftButton from "../../assets/icons/jump_left.svg";
import rightButton from "../../assets/icons/jump_right.svg";
import {TsAdminContext} from "../../context/ts-admin";
import leftButtonEnable from "../../assets/icons/jump_left_enable.svg";
import rightButtonEnable from "../../assets/icons/jump_right_enable.svg";
import {Tooltip} from "../../parts/tooltip";
import * as _ from "lodash";
import {
    getAttendanceLog,
    getStampDateLog,
    getStampDateLogHistory,
    sendAttendanceLog
} from "../../api-service/attendance";
import {toast} from "react-toastify";
import {createRangeSearchDate} from "../../common";

function sortAscending(ascending) {
    return function (a, b) {
        if (a.time === null && b.time === null) {
            return 0;
        }
        if (a.time === null) {
            return 1;
        }
        if (b.time === null) {
            return -1;
        }

        const dateA = new Date(a.time);
        const dateB = new Date(b.time);

        if (ascending) {
            return dateA.getTime() - dateB.getTime();
        }
        return dateB.getTime() - dateA.getTime();
    };
}


export function AttendanceLogPageV2() {
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);

    const [searchData, setSearchData] = useState({
        startDate: subDays(new Date(), 3),
        endDate: new Date(),
        type: "",
        device_id: "",
    });

    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [records, setRecords] = useState<any>([]);
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [listDevices, setListDevices] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortType, setSortType] = useState('desc');
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);


    const itemsPerPage = 50;
    const deviceTooltipText = "端末IDを確認する方法\n1.本製品の電源を ON にします。\n2.本製品が利用可能状態になったら、電源ボタンを 2 秒以上押します。\n3.機能選択画面が表示されます。\n4.機能選択画面で[端末情報表示]ボタンを押します。\n5.端末IDを確認します。";

    const columns = [
        {
            dataField: 'time',
            text: '日時',
            sort: false
        }, {
            dataField: 'device_id',
            text: '端末ID',
            sort: false
        }, {
            dataField: 'stamp_type',
            text: '打刻種別',
            sort: false
        }, {
            dataField: 'id',
            text: 'カードID',
            sort: false
        },
        {
            dataField: 'status',
            text: 'ステータス',
            sort: false
        },
        {
            dataField: 'retry_count',
            text: 'リトライ回数',
            sort: false
        },
        {
            dataField: 'last_modified_date',
            text: '最終実施日時',
            sort: false
        },
        {
            dataField: 'message',
            text: 'エラー',
            sort: false
        }
    ];
    const changeStarDate = (date: any) => {
        setSearchData({
            ...searchData,
            startDate: date
        })
    }
    const changeEndDate = (date: any) => {
        setSearchData({
            ...searchData,
            endDate: date
        })
    }
    useEffect(() => {
        if (userInfo) {
            console.log(userInfo, 11)
            setIsSuperAdmin(userInfo.isSuperAdmin);
        }
    }, [userInfo])

    const getAttendanceData = useCallback(async () => {
        if (orgInfo && orgInfo.currentOrg) {
            setListDevices(orgInfo.devices.filter(e => e != "web"));
            const targetOrg = localStorage.getItem('target-org') || orgInfo?.currentOrg?.OrgId
            setIsLoading(true);
            const getStampDateLogData = async () => {
                const listDate = createRangeSearchDate(searchData.startDate, searchData.endDate);
                let currentIndex = 0;
                let currentKey: any = '0';
                let allItems: any[] = [];
                console.log(listDate.length);
                while (currentIndex < listDate.length) {
                    let currentKey: any = '0';
                    while (currentKey != '') {
                        const response: any = await getStampDateLog({
                            SearchDate: listDate[currentIndex],
                            Status: searchData.type == 'unsent' ? '0' : '',
                            DeviceId: searchData.device_id,
                            TargetOrg: targetOrg,
                            CurrentKey: currentKey
                        })
                        let stampLog = response.data;
                        allItems = [...allItems, ...stampLog.records];
                        if (stampLog.lastKey) {
                            currentKey = stampLog.lastKey.Id
                        } else {
                            currentKey = ''
                        }
                    }
                    console.log("currentIndex", currentIndex)
                    currentIndex++;
                }
                return allItems;
            }

            const getAttendanceLogData = async () => {
                let currentKey: any = '0';
                let allItems: any[] = [];
                while (currentKey != '') {
                    const response: any = await getAttendanceLog({
                        StartDate: formatISO(searchData.startDate, {representation: "date"}),
                        EndDate: formatISO(searchData.endDate, {representation: "date"}),
                        Status: searchData.type == 'unsent' ? '0' : '',
                        DeviceId: searchData.device_id,
                        TargetOrg: targetOrg,
                        CurrentKey: currentKey
                    })
                    let stampLog = response.data;
                    console.log(response);
                    allItems = [...allItems, ...stampLog.records];

                    if (stampLog.lastKey) {
                        currentKey = stampLog.lastKey.Id
                    } else {
                        currentKey = ''
                    }
                }
                return allItems;
            }
            try {
                const allItems = await Promise.all([
                    await getAttendanceLogData(),
                    await getStampDateLogData()
                ]).then(results => results.flat());
                let tmpRecords = _.cloneDeep(allItems);
                tmpRecords.sort(sortAscending(false));
                setRecords(tmpRecords);
                setCurrentItems(tmpRecords.slice(0, itemsPerPage))
                setCurrentPage(1);
                setIsLoading(false);

            } catch (err) {
                console.log("err", err)
            } finally {
                setIsLoading(false);
            }
        }
    }, [searchData, orgInfo]);


    // const getAttendanceData = useCallback(async () => {
    //     if (orgInfo && orgInfo.currentOrg) {
    //         setListDevices(orgInfo.devices.filter(e => e != "web"));
    //         const targetOrg = localStorage.getItem('target-org') || orgInfo?.currentOrg?.OrgId
    //         setIsLoading(true);
    //         try {
    //
    //             let allItems: any[] = [];
    //             const listDate = createRangeSearchDate(searchData.startDate, searchData.endDate);
    //             let currentIndex = 0;
    //             console.log(listDate.length);
    //             while (currentIndex < listDate.length) {
    //                 let currentKey: any = '0';
    //                 while (currentKey != '') {
    //                     const response: any = await getStampDateLog({
    //                         SearchDate: listDate[currentIndex],
    //                         Status: searchData.type == 'unsent' ? '0' : '',
    //                         DeviceId: searchData.device_id,
    //                         TargetOrg: targetOrg,
    //                         CurrentKey: currentKey
    //                     })
    //                     let stampLog = response.data;
    //                     allItems = [...allItems, ...stampLog.records];
    //                     if (stampLog.lastKey) {
    //                         currentKey = stampLog.lastKey.Id
    //                     } else {
    //                         currentKey = ''
    //                     }
    //                 }
    //                 console.log("currentIndex", currentIndex)
    //                 currentIndex++;
    //             }
    //             let tmpRecords = _.cloneDeep(allItems);
    //             tmpRecords.sort(sortAscending(false));
    //             setRecords(tmpRecords);
    //             setCurrentItems(tmpRecords.slice(0, itemsPerPage))
    //             setCurrentPage(1);
    //             setIsLoading(false);
    //
    //         } catch (err) {
    //         } finally {
    //             setIsLoading(false);
    //         }
    //     }
    // }, [searchData, orgInfo]);

    useEffect(() => {
        const newOffset = currentPage * itemsPerPage;
        setCurrentItems(records.slice(newOffset - itemsPerPage, newOffset))
    }, [currentPage])

    useEffect(() => {
        fetchLogReports();
    }, [orgInfo])

    const fetchLogReports = async () => {
        setIsLoading(true);
        await getAttendanceData()
    }

    const sendBulkData = async () => {
        await sendAttendanceLog({}).then((data: any) => {
            //data.success ? toast.success(data.message) : toast.error(data.message);
            setIsSending(false)
        }).catch(e => {
            toast.error(e);
            setIsSending(false);
        });
    }

    const handleBulkSendData = async () => {
        setIsSending(true)
        await sendBulkData();
    }

    const sortRecord = (index) => {
        if (index != 0) {
            return;
        }
        const newSortType = sortType === 'desc' ? 'asc' : 'desc';
        setSortType(newSortType);
        let tmpRecords = _.cloneDeep(records);

        if (newSortType == 'desc') {
            tmpRecords.sort(sortAscending(false));
        } else {
            tmpRecords.sort(sortAscending(true));
        }
        setRecords(tmpRecords);
        const newOffset = currentPage * itemsPerPage;
        setCurrentItems(tmpRecords.slice(newOffset - itemsPerPage, newOffset))
    }

    return (
        <Fragment>
            <div className="log-reports attendance-log">
                <Card className="mt-3 card-container">
                    <Card.Body className="d-flex align-items-center justify-content-between page-title">
                        打刻確認
                        <Button size="sm"
                                className={`border px-4 btn-save`} onClick={handleBulkSendData}
                                disabled={isSending || isSuperAdmin}
                        >
                            未送信データ一括送信
                        </Button>
                    </Card.Body>
                </Card>
                <div className="mt-3">
                    <Form>
                        <Row xs={1} md={3} lg={5} className="d-flex align-items-center pt-3 px-3">
                            <Col xs={12} md={8} lg={5} className="d-flex align-items-center">
                                <Form.Label className="mt-2 me-2 log-form-label" htmlFor="startDate">
                                    期間
                                </Form.Label>
                                <ReactDatePicker
                                    selected={searchData.startDate}
                                    maxDate={new Date()}
                                    dateFormat={"yyyy/MM/dd"}
                                    onFocus={e => e.target.blur()}
                                    className="form-control log-form-control"
                                    customInput={
                                        <input

                                            type="text"
                                            readOnly={true}
                                            id="startDate"
                                            placeholder=""
                                            className={"calendar-icon"}
                                        />
                                    }
                                    onChange={changeStarDate}
                                />
                                <span className="px-2">〜</span>
                                <ReactDatePicker
                                    selected={searchData.endDate}
                                    maxDate={new Date()}
                                    minDate={searchData.startDate}
                                    className="form-control log-form-control"
                                    dateFormat={"yyyy/MM/dd"}
                                    onFocus={e => e.target.blur()}
                                    customInput={
                                        <input
                                            readOnly={true}
                                            type="text"
                                            id="endDate"
                                            placeholder=""
                                            className={"calendar-icon"}
                                        />
                                    }
                                    onChange={changeEndDate}
                                />
                            </Col>
                            <Col xs={8} md={6} lg={3} className="d-flex align-items-center">
                                <Form.Label className="mt-2 me-2 log-form-label">
                                    端末
                                    <Tooltip text={deviceTooltipText} width="400px"/>
                                </Form.Label>
                                <Form.Select aria-label="Default select example"
                                             className="form-control log-form-control"
                                             onChange={(e) => {
                                                 setSearchData({...searchData, device_id: e.target.value})
                                             }}
                                >
                                    <option></option>
                                    {
                                        listDevices.map(e => {
                                            return <option key={e} value={e}>{e}</option>
                                        })
                                    }
                                </Form.Select>
                            </Col>
                            <Col xs={12} md={7} lg={12} className="d-flex align-items-center pt-lg-3">
                                <Form.Label className="mt-2 me-2 log-form-label" htmlFor="inlineFormInputGroup">
                                    ステータス
                                </Form.Label>
                                <Form.Check className="log-form-control pe-2 mb-0 radio-form-check"
                                >
                                    <Form.Check.Input
                                        value=""
                                        type="radio"
                                        name="attendance-level"
                                        id="attendance-level-all"
                                        className="me-2"
                                        checked={searchData?.type === ""}
                                        onChange={e => setSearchData({...searchData, type: e.target.value})}
                                    />
                                    <Form.Check.Label htmlFor="attendance-level-all">全て</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="log-form-control pe-2 mb-0 radio-form-check"
                                >
                                    <Form.Check.Input
                                        value="unsent"
                                        type="radio"
                                        name="attendance-level"
                                        id="attendance-level-unsent"
                                        className="me-2"
                                        checked={searchData?.type === "unsent"}
                                        onChange={e => setSearchData({...searchData, type: e.target.value})}
                                    />
                                    <Form.Check.Label htmlFor="attendance-level-unsent">未送信</Form.Check.Label>
                                </Form.Check>
                                {
                                    isLoading ?
                                        <Button size={'sm'} className="button-search" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                            検索...
                                        </Button>
                                        :
                                        <Button size={'sm'} className="button-search" onClick={fetchLogReports}>
                                            検索
                                        </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </div>
                {records?.length > 0 ?
                    <Table className="mt-3 rounded-0 table-logs table-attendance">
                        <thead>
                        <tr>
                            {
                                columns.map((e, index) => {
                                    if (!e.sort) {
                                        return (
                                            <th key={index} className={"xs-column heading"}>
                                                {e.text}
                                            </th>
                                        )
                                    } else {
                                        return (
                                            <th key={index} className={"xs-column heading"}>
                                                <div className="d-flex cursor-pointer"
                                                     onClick={() => sortRecord(index)}>
                                                    {e.text}
                                                    {
                                                        e.sort && <div
                                                            className={`${sortType == 'desc' ? 'btn-sort-desc' : 'btn-sort-asc'}`}>
                                                            <svg
                                                                width="12" height="12"
                                                                viewBox="0 0 12 12"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path fillRule="evenodd"
                                                                      clipRule="evenodd"
                                                                      d="M2.21537 7.15392C2.03075 7.33854 2.03075 7.59238 2.21537 7.777L5.6769 11.1693C5.86152 11.3539 6.13844 11.3539 6.32306 11.1693L9.80767 7.777C9.99229 7.59238 9.99229 7.33854 9.80767 7.15392L9.16152 6.53084C8.9769 6.34623 8.69998 6.34623 8.51537 6.53084L7.43075 7.59238C7.24614 7.777 6.92306 7.66161 6.92306 7.38469V1.15392C6.92306 0.923152 6.71537 0.692383 6.46152 0.692383H5.53844C5.2846 0.692383 5.0769 0.946229 5.0769 1.15392V7.38469C5.0769 7.66161 4.75383 7.777 4.56921 7.59238L3.4846 6.53084C3.29998 6.34623 3.02306 6.34623 2.83844 6.53084L2.21537 7.15392Z"
                                                                      fill="#747474"/>
                                                            </svg>
                                                        </div>
                                                    }
                                                </div>
                                            </th>
                                        )
                                    }
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            currentItems.length > 0 && records.length > 0 && currentItems.map((e, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{format(new Date(e.time), `yyyy/MM/dd`)} {format(new Date(e.time), `HH:mm`)}</td>
                                        <td>{e.device_id}</td>
                                        <td>{e.stamp_type}</td>
                                        <td>{e.card_id}</td>
                                        <td>{e.status != 1 ? <span className={"status-unsent"}>未送信</span> :
                                            <span>送信済み</span>}</td>
                                        <td>{e.retry_count}</td>
                                        <td>{format(new Date(e.last_modified_date), `yyyy/MM/dd`)} {format(new Date(e.last_modified_date), `HH:mm`)}</td>
                                        <td>{e.error_message}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                    :
                    <h6 className="text-center pt-5 fw-bold">{!isLoading ? 'データがありません。' : '検索中・・・・'}</h6>
                }


                {
                    records && records.length > 0 && <nav>
                        <ul className="pagination justify-content-center">
                            <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => setCurrentPage(1)}>
                                    {currentPage === 1 ?
                                        <img src={leftButton}/>
                                        :
                                        <img src={leftButtonEnable}/>
                                    }
                                </a>
                            </li>
                            <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>
                                    {currentPage === 1 ?
                                        <IconArrow type='left'/>
                                        :
                                        <IconArrow type='left' active={true}/>
                                    }
                                </a>
                            </li>
                            <li className="pt-2">（{currentPage}/{Math.ceil(records.length / itemsPerPage)}）</li>
                            <li className={currentPage === Math.ceil(records.length / itemsPerPage) ? "page-item disabled" : "page-item"}>
                                <a className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>
                                    {currentPage === Math.ceil(records.length / itemsPerPage) ?
                                        <IconArrow type='right'/>
                                        :
                                        <IconArrow type='right' active={true}/>
                                    }
                                </a>
                            </li>
                            <li className={currentPage === Math.ceil(records.length / itemsPerPage) ? "page-item disabled" : "page-item"}>
                                <a className="page-link"
                                   onClick={() => setCurrentPage(Math.ceil(records.length / itemsPerPage))}>
                                    {currentPage === Math.ceil(records.length / itemsPerPage) ?
                                        <img src={rightButton}/>
                                        :
                                        <img src={rightButtonEnable}/>
                                    }
                                </a>
                            </li>
                        </ul>
                    </nav>
                }

            </div>
        </Fragment>
    )

}